import React, { FC } from "react";
import cls from "../home/homePage.module.scss";

interface IProps {
    translationsData: { [key: string]: string };
}

const HomeIntro: FC<IProps> = ({ translationsData }) => {
    const showWelcomeText = translationsData.HomeHeading !== "empty" || translationsData.HomeIntro !== "empty";

    return (
        <>
            {showWelcomeText ?
                <div className="container">
                    <div className={cls["welcome-title"]}>
                        {translationsData.HomeHeading !== "empty" && (
                            <h1>{translationsData.HomeHeading}</h1>
                        )}
                        {translationsData.HomeIntro !== "empty" && (
                            <p>{translationsData.HomeIntro}</p>
                        )}
                    </div>
                </div>
                : null
            }
        </>
    );
};

export default HomeIntro;
