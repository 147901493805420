import cls from "./blockHeader.module.scss";
import React, {FC} from "react";

const BlockHeader: FC<{ title: string }> = ({title}) => {
    return (
        <div className={cls["block-header"]}>
            <h2 className={cls["block-header__title__custom"]}>
                <span className={cls["title-line-fms"]}></span>
                <span className={cls["title-name-fms"]}>
                    {title}
                </span>
                <span className={cls["title-line-fms"]}></span>
            </h2>
        </div>
    )
}

export default BlockHeader;
